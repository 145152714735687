import type { Dictionary, TranslationComponentOptionsProp } from "@/modules/i18n/components/types"
import type { I18nLocale, I18nNamespace } from "@/modules/i18n/types"
import type { ReactNode } from "react"

import { Children, cloneElement, createElement, Fragment } from "react"

import { fetcher } from "@/modules/fetch/fetcher"
import { AVAILABLE_LOCALES } from "@/modules/i18n/constants"
import { DEFAULT_LOCALE } from "@/modules/locales/constants"
import { logger } from "@/modules/monitoring/logger"
import { getAssetsUrl } from "@/modules/staticAssets/assetsManager/assetsManager"

type PluralRuleCount = "zero" | "one" | "two" | "few" | "many" | "other"
type PluralTypeName = "english" | "czech" | "polish"

const CACHE_VERSION = 4

const pluralRules: {
  pluralTypes: Record<PluralTypeName, (count: number) => PluralRuleCount>
  pluralTypeToLanguages: Record<PluralTypeName, I18nLocale[]>
} = {
  // Phrases UI configuration
  pluralTypeToLanguages: {
    czech: ["cs"],
    english: ["ca", "da", "de", "en", "es", "fi", "fr", "gl", "it", "nl", "no", "pt", "sv"],
    polish: ["pl"],
  },

  // Hybrid implementation between Phrase UI and the Intl.PluralRules specification
  pluralTypes: {
    czech: (count: number): PluralRuleCount => {
      if (count === 0) {
        return "zero"
      }
      if (count === 1) {
        return "one"
      }
      if (count >= 2 && count <= 4) {
        return "few"
      }
      return "other"
    },
    english: (count: number): PluralRuleCount => {
      if (count === 0) {
        return "zero"
      }
      if (count === 1) {
        return "one"
      }
      return "other"
    },
    polish: (count: number): PluralRuleCount => {
      if (count === 0) {
        return "zero"
      }
      if (count === 1) {
        return "one"
      }
      if (count >= 2 && count <= 4) {
        return "few"
      }
      if (count >= 5 && count <= 19) {
        return "many"
      }
      return "other"
    },
  },
}

const memoizedPluralRuleNames: Partial<Record<I18nLocale, PluralTypeName>> = {}

function getPluralSuffix(locale: I18nLocale, count: number): PluralRuleCount | null {
  let ruleName = memoizedPluralRuleNames[locale]

  if (!ruleName) {
    ruleName = Object.keys(pluralRules.pluralTypeToLanguages).find(pluralTypeName =>
      pluralRules.pluralTypeToLanguages[pluralTypeName as PluralTypeName].includes(locale)
    ) as PluralTypeName | undefined
  }

  if (ruleName) {
    return pluralRules.pluralTypes[ruleName](count)
  }

  return null
}

function resetPhrases(): Record<I18nLocale, Dictionary> {
  return AVAILABLE_LOCALES.reduce((acc, locale) => ({ ...acc, [locale]: {} }), {} as Record<I18nLocale, Dictionary>)
}

function resetNamespaces(): Record<I18nLocale, I18nNamespace[]> {
  return AVAILABLE_LOCALES.reduce(
    (acc, locale) => ({ ...acc, [locale]: [] }),
    {} as Record<I18nLocale, I18nNamespace[]>
  )
}

const interpolationRegex = /%\{(.*?)\}/g

function createReplaceCallback(options: Record<string, unknown>) {
  return function replaceCallback(
    fullInterpolationExpression: string,
    interpolationArgument: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    const result = options[interpolationArgument]
    return result !== undefined ? result : fullInterpolationExpression
  }
}

/**
 * Forked and enhanced code from the PR adding the support of React interpolation in node-polyglot
 * @link https://github.com/airbnb/polyglot.js/pull/171
 */
function replaceReact(cb: (match0: string, match1: string) => unknown): string | JSX.Element {
  // @ts-expect-error this is the original translation string
  const phrase = this as string
  let index = 0
  const children = []
  const childrenKey: string[] = []
  const matches = Array.from(phrase.matchAll(interpolationRegex))

  matches.forEach(match => {
    if (match.index !== undefined) {
      // First part of the translation
      if (match.index > index) {
        const result = phrase.substring(index, match.index)
        children.push(result)
        childrenKey.push(result)
      }

      // Replace what has to be replaced
      children.push(cb(match[0], match[1]))
      childrenKey.push(match[1])
      index = match.index + match[0].length
    }
  })

  // Last part of the translation
  if (index < phrase.length) {
    const result = phrase.substring(index)
    children.push(phrase.substring(index))
    childrenKey.push(result)
  }

  // if there is only string/number interpolation
  if (children.every(child => ["string", "number"].includes(typeof child))) {
    return children.join("")
  }

  // otherwise, let's wrap all the children with a Fragment
  return createElement(
    Fragment,
    null,
    Children.map(children, (child, idx) =>
      // Clone the string/element part to add a key
      cloneElement(typeof child === "object" ? child : createElement(Fragment, null, child), { key: childrenKey[idx] })
    )
  )
}

export class Phrases {
  #namespaces: Record<I18nLocale, I18nNamespace[]>

  #phrases: Record<I18nLocale, Dictionary>

  #promises: Record<I18nLocale, Partial<Record<I18nNamespace, Promise<void>>>>

  constructor() {
    this.#namespaces = resetNamespaces()
    this.#phrases = resetPhrases()
    this.#promises = resetPhrases()
  }

  #getInterpolationKey(
    key: string,
    options: TranslationComponentOptionsProp<string | ReactNode> & { locale: I18nLocale }
  ): string {
    const { locale, count } = options
    let interpolationKey = key

    // If the options has a count property, we update the translation key with the plural suffix
    if (locale && typeof count === "number") {
      const suffix = getPluralSuffix(locale, count)

      if (suffix) {
        interpolationKey = `${key}_${suffix}`
      }

      // In case of a missing plural, we try to use the "other" suffix
      if (!this.#phrases[locale][interpolationKey] && interpolationKey !== "key") {
        interpolationKey = `${key}_other`
      }

      // If not found, use the original key
      if (!this.#phrases[locale][interpolationKey]) {
        interpolationKey = key
      }
    }

    return interpolationKey
  }

  add(locale: I18nLocale, dictionary: Dictionary): void {
    Object.entries(dictionary).forEach(([key, value]) => {
      const namespace = key.split(".")[0] as I18nNamespace

      if (!this.#namespaces[locale].includes(namespace)) {
        this.#namespaces[locale].push(namespace)
      }

      this.#phrases[locale][key] = value
    })
  }

  clear(locale?: I18nLocale): void {
    if (locale) {
      this.#namespaces[locale] = []
      this.#phrases[locale] = {}
    } else {
      this.#namespaces = resetNamespaces()
      this.#phrases = resetPhrases()
    }
  }

  has(key: string, options: TranslationComponentOptionsProp<string | ReactNode> & { locale: I18nLocale }): boolean {
    const { locale = DEFAULT_LOCALE } = options
    const interpolationKey = this.#getInterpolationKey(key, options)

    return !!this.#phrases[locale][interpolationKey]
  }

  hasNamespace(
    namespace: I18nNamespace,
    options: TranslationComponentOptionsProp<string | ReactNode> & { locale: I18nLocale }
  ): boolean {
    const { locale } = options

    return this.#namespaces[locale].includes(namespace)
  }

  t(key: string, options: TranslationComponentOptionsProp & { locale: I18nLocale }): string {
    const { locale, ...otherOptions } = options
    const interpolationKey = this.#getInterpolationKey(key, options)
    const phrase = this.#phrases[locale][interpolationKey]

    // In case of no translation found, we return the phrase key
    if (!phrase) {
      return key
    }

    // If there is no interpolation to perform, we send the translation from the dictionary
    if (!Object.keys(otherOptions).length) {
      return phrase
    }

    return phrase.replace(interpolationRegex, createReplaceCallback(otherOptions))
  }

  tReact(key: string, options: TranslationComponentOptionsProp<ReactNode> & { locale: I18nLocale }): ReactNode {
    const { locale, ...otherOptions } = options
    const interpolationKey = this.#getInterpolationKey(key, options)
    const phrase = this.#phrases[locale][interpolationKey]

    // In case of no translation found, we return the phrase key
    if (!phrase) {
      return key
    }

    // If there is no interpolation to perform, we send the translation from the dictionary
    if (!Object.keys(otherOptions).length) {
      return phrase
    }

    return replaceReact.call(phrase, createReplaceCallback(otherOptions))
  }

  async downloadDictionary(locale: I18nLocale, namespace: I18nNamespace): Promise<void> {
    if (this.#promises[locale][namespace] === undefined) {
      const assetPrefixUrl = getAssetsUrl()
      // Note: the parameter "v" is used as a cache busting mechanism.
      // Update this parameter if you need to force a cache bust for all
      // translations (remember that translations are cached for 1h by default -
      // see .circleci/config.yml)
      // Last cache bust date (with v=4): 2024/09/23
      const url = `${assetPrefixUrl || window.origin}/locales/${locale}/${namespace}.json?v=${CACHE_VERSION}`
      this.#promises[locale][namespace] = fetcher(url, {
        serviceDomain: "EXTERNAL",
      })
        .then(response => response.json())
        .then(dictionary => {
          this.add(locale, dictionary)
        })
        .catch(error => {
          logger.error(`An error occured while fetching the translation file "${url}": ${error.message}.`)
          return Promise.resolve()
        })
    }

    return this.#promises[locale][namespace]
  }
}
